import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import rightImage from '../../../images/Treatment_Options_Callout.png'

const metaTags = {
  description: 'What to consider when discussing your child\'s treatment option with your doctor.',
  keywords: 'Homepage',
  title: 'Treatment Options | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>When considering treatment options</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        {/* <h2>Considering treatment options to fit your child’s life</h2> */}
        <p>There are several central precocious puberty (CPP) therapies available that can stop the release of puberty-causing hormones. There are also different ways that these medications can be administered, and the frequency your child needs to receive therapy may also vary.  That’s why it’s important to talk with your doctor about what is best for your child. <strong>Be sure to ask about the FDA-approved, 12-month <nobr>SUPPRELIN<sup>&reg;</sup> LA</nobr> implant</strong>.</p>
        <p>You should keep all scheduled visits to the doctor. Your child’s doctor will do regular exams and blood tests to check for signs of puberty. </p>
      </Col>
      <Col xs={12} md={6}>
        <img src={rightImage} alt="Questions to consider" title="Questions to consider" />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/d66720ac-93d2-4720-b1d8-67f71581bf3c/d66720ac-93d2-4720-b1d8-67f71581bf3c_source__v.pdf"
          LinkCaption="Download a tool to help you discuss treatment options with your child’s doctor - what are my childs treatment options"
        >
          Download a tool to help you discuss treatment options with your child’s doctor
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
